*{
  margin: 0;
  padding: 0;
}
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    margin-top: 4rem; /* To ensure content does not overlap with navbar */
  }
  
  @media (max-width: 600px) {
    .content {
      padding: 10px;
    }
  }
  
/* Add this CSS to your App.css or a similar stylesheet */

/* Add this CSS to your App.css or a similar stylesheet */

@media print {
  .action-button {
    display: none !important; /* Ensure action buttons are hidden */
  }

  body * {
    visibility: hidden;
  }

  .printableArea, .printableArea * {
    visibility: visible;
  }

  .printableArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

  